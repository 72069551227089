import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useAlertMessageStore } from 'store/store';

const useStyles = makeStyles((theme) => ({
  topWarning: {
    marginTop: '50px',
  },

}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertMessage(props) {
  const { message, errorList, severity, resetAllStates } = props;
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    resetAllStates && resetAllStates();
    useAlertMessageStore.setState({ alertMessage: false})
  };

  return (
    <Snackbar className={classes.topWarning} open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
      <Alert onClose={handleClose} severity={severity} key={Math.random()}>
        {message}
      { errorList &&
        <>
        <List>
          { errorList.map((error, key) => {
            return (
              <ListItem key={key}>
                <ListItemText primary={error} />
              </ListItem>
            )
           } ) }
        </List>
        </>
      }
      </Alert>
    </Snackbar>
  );
}
