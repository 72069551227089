import { create } from 'zustand';


export const useAlertMessageStore = create( (set, get) => ({
    alertMessage: false,

    updateAlertMessage: (newAlertMessage) => {
        set({ alertMessage: newAlertMessage })
    },
    resetAlertMessage: () => {
        set({ alertMessage: false })
    },
}));

export const useHeaderStore = create( (set, get) => ({
    title: false,
    help: "help.playergroup",

    updateHeader: (newHeader) => {
        set({ title: newHeader.title, help: newHeader.help })
    },
    resetHeader: () => {
        set({ 
            title: false,
            help: "help.settings"
        })
    },
}));

export const useReloadStore = create(() => ({ forceReload: false }));