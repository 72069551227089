import '../css/App.css';
import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import Pie from '../components/Pie';
import StatisticDataService from '../services/statistic.service';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useHeaderStore } from '../store/store';

const useStyles = theme => ({
      speedDial: {
        position: 'fixed',
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        },
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
      },
      donuts: {
        justifyContent: 'center',
      }
});
  
  class Home extends Component {
  
    constructor(props) {
      super(props);
      this.receiveStatistics = this.receiveStatistics.bind(this);
      
      this.state = {
        isLoading: true,
      }

    }

    componentDidMount() {
      this.receiveStatistics();
      useHeaderStore.setState({title: 'Übersicht'})
    }

    receiveStatistics(){
      StatisticDataService.findAll()
      .then(response => {
        this.setState({
          statistic: response.data,
          isLoading: false,
        })
      })
      .catch(err => {
        console.log(err)
      })
    }
   

  render() {
    const { isLoading, statistic } = this.state
    const { classes } = this.props;

    
    if(isLoading) {
      return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
      </Backdrop>
      )
    } else {
      return(
      <Box >
        <Grid container className={classes.donuts}>
          <Grid item>
            <Typography align='center'>Accounts gesamt (aktiv)</Typography>
            <Pie percentage={(statistic.accounts.cnt_active/statistic.accounts.cnt_total*100)} colour='green' count={`${statistic.accounts.cnt_total} (${statistic.accounts.cnt_active}) `} />
          </Grid>
          <Grid item>
            <Typography align='center'>Sammlungen gesamt</Typography>
            <Pie percentage={100} colour='green' count={statistic.collections.cnt_total} />
          </Grid>
          <Grid item>
            <Typography align='center'>Spiele gesamt</Typography>
            <Pie percentage={100} colour='green' count={statistic.games.cnt_total} />
          </Grid>
        </Grid>
      </Box>
      )
    }
  }
}

export default withStyles(useStyles)(Home)