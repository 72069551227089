import Box from "@mui/material/Box"; // GLOBAL CUSTOM COMPONENT
import OrderForm from "./order-form";
import OrderDataService from "services/order.service";
import SevdeskDataService from "services/sevdesk.service";
import { useAlertMessageStore, useHeaderStore } from "store/store";
import { useEffect, useState } from "react";

export default function CreateOrder(props) {

  const [articles, setArticles] = useState([])
  const [customers, setCustomers] = useState([])

  function retrieveArticles() {
    OrderDataService.getAllArticles()
      .then((response) => {
        setArticles(response.data)
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function retrieveCustomers() {
    SevdeskDataService.getCustomers()
      .then((response) => {
        let customerList = response.data;
        customerList.unshift({id: 1, surname: 'Oehler', firstname: 'Christopher'})
        setCustomers(response.data)
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    retrieveArticles();
    retrieveCustomers();
    useHeaderStore.setState({title: 'Bestellung erfassen'})
  }, []);
  const INITIAL_VALUES = {
    customer: "",
    article: "",
    quantity: "",
    createdAt: new Date()
  };

  
  const handleFormSubmit = values => {
    OrderDataService.create(values)
      .then((response) => {
        if(response.status === 200) {
            useAlertMessageStore.setState({ alertMessage: { severity: "success", message: response.data.message } })
            props.history.push('/shop/orders_pending'); 
        } else {          
            useAlertMessageStore.setState({ alertMessage: { severity: "error", message: response.data.message } })
        }
      })
      .catch((e) => {
        useAlertMessageStore.setState({ alertMessage: { severity: "error", message: "Fehler beim Erfassen der Bestellung" } })
      });
  }

  return <Box py={4}>

      <OrderForm initialValues={INITIAL_VALUES} handleFormSubmit={handleFormSubmit} articleValues={articles} customerValues={customers} />
    </Box>;
};
