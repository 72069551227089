import { useCallback, useState } from "react";
import OrderDataService from "services/order.service";
import SevdeskDataService from "services/sevdesk.service";
import { useAlertMessageStore, useReloadStore } from "store/store";

// ================================================================

// ================================================================

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}
export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// ================================================================

// ================================================================

const useOrderActions = (props) => {
  const { listData = [], defaultSort = "name", defaultOrder = "asc" } = props;
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [orderBy, setOrderBy] = useState(defaultSort);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState(defaultOrder);
  const [openModal, setOpenModal] = useState(false);
  const toggleDeleteDialog = useCallback(
    () => setOpenModal((open) => !open),
    []
  );

  const handleFormSubmit = (values) => {
    const data = {
      orders: selected,
      data: values,
    };
    OrderDataService.updateMultiple(data)
      .then((response) => {
        if (response.status === 200) {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "success",
              message: response.data.message,
            },
          });
          useReloadStore.setState({
            forceReload: true,
          });
        } else {
          useAlertMessageStore.setState({
            alertMessage: { severity: "error", message: response.data.message },
          });
        }
      })
      .catch((e) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Bearbeiten der Bestellung",
          },
        });
      });
  };

  const handleDeleteClick = (id) => {
    OrderDataService.deleteSingle(id)
      .then((response) => {
        toggleDeleteDialog();
        if (response.status === 200) {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "success",
              message: response.data.message,
            },
          });
          useReloadStore.setState({
            forceReload: true,
          });
        } else {
          useAlertMessageStore.setState({
            alertMessage: { severity: "error", message: response.data.message },
          });
        }
      })
      .catch((e) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Löschen der Bestellung",
          },
        });
      });
  };

  const handleInvoiceClick = (invoice) => {
    console.dir(invoice);
    if (invoice.id === null) {
      useAlertMessageStore.setState({
        alertMessage: {
          severity: "error",
          message: "Es wurde keine Rechnung gepflegt",
        },
      });
    } else {
      SevdeskDataService.getInvoice(invoice.id).then((response) => {
        const { status } = response.data.invoice;
        if (status === "200" || status === "1000") {
          SevdeskDataService.getPDF(invoice.id).then((response) => {
            const filename =
              response.headers["content-disposition"].split("filename=")[1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          });
        } else {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "warning",
              message: "Die Rechnung befindet sich noch im Entwurf",
            },
          });
        }
      });
    }
  };

  const filteredList = stableSort(
    listData,
    getComparator(order, orderBy)
  );
  return {
    paginationModel,
    order,
    orderBy,
    selected,
    openModal,
    filteredList,
    setPaginationModel,
    setSelected,
    toggleDeleteDialog,
    handleFormSubmit,
    handleDeleteClick,
    handleInvoiceClick,
  };
};
export default useOrderActions;
