import http from "../http-common";

class SessionDataService {
  getAll() {
    return http.get(`/sessions`);
  }

  get(id) {
    return http.get(`sessions/${id}`);
  }

  delete(id) {
    return http.delete(`/sessions/${id}`);
  }

}

export default new SessionDataService();
