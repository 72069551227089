import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Delete, Edit } from "@mui/icons-material";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import EditOrders from "pages-sections/EditOrders";
import OrderDeleteButton from "components/order/OrderDeleteButton";
import OrderDeleteDialog from "components/order/order-delete-dialog";
import SearchArea from "components/dashboard/SearchArea";
import { StatusWrapper } from "pages-sections/StyledComponents";

import OrderDataService from "services/order.service";
import useOrderActions from "hooks/useOrderActions";
import { useHeaderStore, useReloadStore } from "store/store";

const currencyFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

// TABLE HEADING DATA LIST
const tableHeading = [
  {
    field: "customer",
    valueGetter: (params) => {
      return `${params.row.customer.firstname || ""} ${
        params.row.customer.surname || ""
      }`;
    },
    headerName: "Besteller",
    align: "left",
    width: 200,
  },
  {
    field: "article",
    valueGetter: (params) => {
      return `${params.row.article.id}` || "";
    },
    headerName: "Art.-Nr.",
    align: "left",
    width: 100,
  },
  {
    field: "title",
    valueGetter: (params) => {
      return `${params.row.article.title}` || "";
    },
    headerName: "Titel",
    align: "left",
    width: 300,
  },
  {
    field: "quantity",
    headerName: "Anzahl",
    align: "center",
    width: 60,
  },
  {
    field: "amount",
    valueGetter: (params) => {
      return params.row.price * params.row.quantity;
    },
    headerName: "EKnetto",
    align: "center",
    width: 85,
    type: "number",
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  },
  {
    field: "specialPrice",
    headerName: "VKbrutto",
    align: "center",
    width: 85,
    type: "number",
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  },
  {
    field: "createdAt",
    headerName: "Erfasst am",
    align: "left",
    width: 100,
    valueFormatter: ({ value }) => format(new Date(value), "dd.MM.yyyy"),
  },
  {
    field: "available",
    headerName: "Lieferbar",
    align: "center",
    width: 80,
    renderCell: (params) => {
      return (
        <StatusWrapper status={params.value}>{params.value}</StatusWrapper>
      );
    },
  },
  {
    field: "availableFrom",
    headerName: "Lieferbar ab",
    align: "left",
    width: 200,
  },
  {
    field: "action",
    headerName: "Aktion",
    align: "center",
    width: 75,
    renderCell: (params) => {
      return (
        <>
          <IconButton href={`/shop/orders/${params.row.id}`}>
            <Edit />
          </IconButton>

          <OrderDeleteButton order={params.row} />
        </>
      );
    },
  },
];

export default function OrderList(props) {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [editOrders, setEditOrders] = useState(false);
  const forceReload = useReloadStore(state => state.forceReload);

  function retrieveOrderDetails() {
    OrderDataService.getPending()
      .then((response) => {
        setOrders(response.data);
        setFilteredOrders(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleSearch(value) {
    setFilteredOrders(
      orders.filter((order) => {
        return (
          order.customer.firstname
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          order.customer.surname.toLowerCase().includes(value.toLowerCase()) ||
          order.article.title.toLowerCase().includes(value.toLowerCase()) ||
          order.article.id
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          order.available.toLowerCase().includes(value.toLowerCase())
        );
      })
    );
  }

  const { openModal, filteredList, setSelected, handleFormSubmit } =
    useOrderActions({
      listData: filteredOrders,
      defaultSort: "available",
      defaultOrder: "asc",
    });

  useEffect(() => {
    retrieveOrderDetails();
    useHeaderStore.setState({ title: "Offene Bestellungen" });
  }, []);

  useEffect(() => {
    if(forceReload) {
      retrieveOrderDetails();
      setSelected([]);
      setEditOrders(false);
      useReloadStore.setState({
        forceReload: false,
      });
    }
  }, [forceReload]);

  return (
    <Box py={4} maxWidth={"1400px"}>
      <SearchArea
        handleSearch={handleSearch}
        buttonText="Bestellung erfassen"
        buttonLink="/shop/orders/create"
        searchPlaceholder="Bestellung suchen..."
        secondaryButton={{
          Action: () => setEditOrders(!editOrders),
          Text: "Bestellungen bearbeiten",
        }}
      />

      {editOrders && <EditOrders onSubmit={handleFormSubmit} />}

      <DataGrid
        checkboxSelection={editOrders}
        autoHeight
        width={"1270px"}
        rows={filteredList}
        columns={tableHeading}
        onSelectionModelChange={(newSelection) => {
          setSelected(newSelection);
        }}
      />
    </Box>
  );
}
