import Box from "@mui/material/Box"; // GLOBAL CUSTOM COMPONENT
import { H3 } from "components/Typography"; // Local CUSTOM COMPONENT
import OrderForm from "./order-form";
import OrderDataService from "services/order.service";
import SevdeskDataService from "services/sevdesk.service";
import { useAlertMessageStore, useHeaderStore } from "store/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Edit from "@mui/icons-material/Edit";
import { Formik } from "formik";
import * as yup from "yup"; // GLOBAL CUSTOM COMPONENTS
import { Paragraph } from "components/Typography";

export default function OrderDetails(props) {
  const [order, setOrder] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [editPrice, setEditPrice] = useState(false);
  const [editSpecialPrice, setEditSpecialPrice] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleClickEditPrice = () => setEditPrice((edit) => !edit);
  const handleClickEditSpecialPrice = () =>
    setEditSpecialPrice((edit) => !edit);

  let { id } = useParams();

  function retrieveOrderDetails() {
    OrderDataService.getSingle(id)
      .then((response) => {
        setOrder(response.data);
        setEditPrice(response.data.editPrice);
        setEditSpecialPrice(response.data.editSpecialPrice);
        if (response.data.customer.id !== 1) {
          retrieveInvoiceDetails(response.data.customer.id);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Laden der Bestellung",
          },
        });
      });
  }

  function retrieveInvoiceDetails(id) {
    SevdeskDataService.getInvoices(id)
      .then((response) => {
        setInvoices(response.data);
      })
      .catch((e) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Laden der Rechnung",
          },
        });
      });
  }

  const VALIDATION_SCHEMA = yup.object().shape({
    customer: yup
      .object()
      .nullable(true)
      .required("Bitte einen Besteller eingeben"),
    article: yup.object().required("Bitte einen Artikel eingeben"),
    quantity: yup.number().required("Bitte eine Menge eingeben"),
    price: yup.number().nullable(true),
    specialPrice: yup.number().nullable(true),
    createdAt: yup.date().required("Bitte ein Erfassungsdatum eingeben"),
    orderedAt: yup.date().nullable(true),
    deliveredAt: yup.date().nullable(true),
  });

  const handleFormSubmit = (values) => {
    if (editPrice === false && values.orderedAt === null) {
      values.price = null;
    }
    if (editSpecialPrice === false && values.orderedAt === null) {
      values.specialPrice = null;
    }
    OrderDataService.update(values.id, values)
      .then((response) => {
        if (response.status === 200) {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "success",
              message: response.data.message,
            },
          });
          props.history.push("/shop/orders");
        } else {
          useAlertMessageStore.setState({
            alertMessage: { severity: "error", message: response.data.message },
          });
        }
      })
      .catch((e) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Bearbeiten der Bestellung",
          },
        });
      });
  };

  useEffect(() => {
    retrieveOrderDetails();
    useHeaderStore.setState({ title: "Bestelldetails" });
  }, []);

  return (
    <Box py={4}>
      <Card
        sx={{
          p: 6,
        }}
      >
        {!isLoading && (
          <Formik
            enableReinitialize={true}
            onSubmit={handleFormSubmit}
            initialValues={order}
            validationSchema={VALIDATION_SCHEMA}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      name="customer"
                      label="Besteller"
                      color="info"
                      size="medium"
                      placeholder="Besteller"
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                      value={`${values.customer?.firstname} ${values.customer?.surname}`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!touched.customer && !!errors.customer}
                      helperText={touched.customer && errors.customer}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      name="article"
                      label="Artikel"
                      color="info"
                      size="medium"
                      placeholder="Artikel"
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                      value={values.article?.id + ": " + values.article?.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!touched.article && !!errors.article}
                      helperText={touched.article && errors.article}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      name="price"
                      color="info"
                      size="medium"
                      disabled={!editPrice}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ lang: "de-DE" }}
                      onBlur={handleBlur}
                      value={values.price}
                      label="Einkaufspreis"
                      onChange={handleChange}
                      placeholder="Einkaufspreis"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle price edit"
                              edge="end"
                              onClick={handleClickEditPrice}
                            >
                              <Edit />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={!!touched.price && !!errors.price}
                      helperText={touched.price && errors.price}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      name="specialPrice"
                      color="info"
                      size="medium"
                      disabled={!editSpecialPrice}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ lang: "de-DE" }}
                      onBlur={handleBlur}
                      value={values.specialPrice}
                      label="Sonderpreis"
                      onChange={handleChange}
                      placeholder="Sonderpreis"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle price edit"
                              edge="end"
                              onClick={handleClickEditSpecialPrice}
                            >
                              <Edit />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={!!touched.specialPrice && !!errors.specialPrice}
                      helperText={touched.specialPrice && errors.specialPrice}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      name="quantity"
                      color="info"
                      size="medium"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      onBlur={handleBlur}
                      value={values.quantity}
                      label="Menge"
                      onChange={handleChange}
                      placeholder="Menge"
                      error={!!touched.quantity && !!errors.quantity}
                      helperText={touched.quantity && errors.quantity}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Autocomplete
                      options={invoices}
                      value={values.invoice?.id ? values.invoice : null}
                      getOptionLabel={(option) => option.invoiceNo}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.invoiceNo} (
                          {format(new Date(option.invoiceDate), "dd.MM.yyyy")})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rechnungs-Nr."
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off",
                            "aria-autocomplete": "none",
                          }}
                          onBlur={handleBlur}
                          error={!!touched.invoice && !!errors.invoice}
                          helperText={touched.invoice && errors.invoice}
                        />
                      )}
                      onChange={(e, value) => setFieldValue("invoice", value)}
                      autoComplete="off"
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <DatePicker
                      label="Erfasst am"
                      value={
                        values.createdAt ? new Date(values.createdAt) : null
                      }
                      onChange={(newValue) =>
                        setFieldValue(
                          "createdAt",
                          newValue ? format(newValue, "yyyy-MM-dd") : null
                        )
                      }
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          sx: {
                            mb: 1,
                          },
                          size: "medium",
                          fullWidth: true,
                          error: Boolean(
                            !!touched.createdAt && !!errors.createdAt
                          ),
                          helperText: touched.createdAt && errors.createdAt,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <DatePicker
                      label="Bestellt am"
                      value={
                        values.orderedAt ? new Date(values.orderedAt) : null
                      }
                      onChange={(newValue) =>
                        setFieldValue(
                          "orderedAt",
                          newValue ? format(newValue, "yyyy-MM-dd") : null
                        )
                      }
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        field: {
                          clearable: true,
                        },
                        textField: {
                          sx: {
                            mb: 1,
                          },
                          size: "medium",
                          fullWidth: true,
                          error: Boolean(
                            !!touched.orderedAt && !!errors.orderedAt
                          ),
                          helperText: touched.orderedAt && errors.orderedAt,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <DatePicker
                      label="Geliefert am"
                      value={
                        values.deliveredAt ? new Date(values.deliveredAt) : null
                      }
                      onChange={(newValue) =>
                        setFieldValue(
                          "deliveredAt",
                          newValue ? format(newValue, "yyyy-MM-dd") : null
                        )
                      }
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        field: {
                          clearable: true,
                        },
                        textField: {
                          sx: {
                            mb: 1,
                          },
                          size: "medium",
                          fullWidth: true,
                          error: Boolean(
                            !!touched.deliveredAt && !!errors.deliveredAt
                          ),
                          helperText: touched.deliveredAt && errors.deliveredAt,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <Button variant="contained" color="info" type="submit">
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        )}
      </Card>
    </Box>
  );
}
