import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Delete, Edit } from "@mui/icons-material";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import EditOrders from "pages-sections/EditOrders";
import OrderDeleteButton from "components/order/OrderDeleteButton";
import OrderInvoiceButton from "components/order/OrderInvoiceButton";
import { StatusWrapper } from "pages-sections/StyledComponents";
import SearchArea from "components/dashboard/SearchArea";

import OrderDataService from "services/order.service";
import useOrderActions from "hooks/useOrderActions";
import { useHeaderStore, useReloadStore } from "store/store";

// TABLE HEADING DATA LIST
const tableHeading = [
  {
    field: "customer",
    valueGetter: (params) => {
      return `${params.row.customer.firstname || ""} ${
        params.row.customer.surname || ""
      }`;
    },
    headerName: "Besteller",
    align: "left",
    width: 200,
  },
  {
    field: "article",
    valueGetter: (params) => {
      return `${params.row.article.id}` || "";
    },
    headerName: "Art.-Nr.",
    align: "left",
    width: 100,
  },
  {
    field: "title",
    valueGetter: (params) => {
      return `${params.row.article.title}` || "";
    },
    headerName: "Titel",
    align: "left",
    width: 300,
  },
  {
    field: "quantity",
    headerName: "Anzahl",
    align: "center",
    width: 60,
  },
  {
    field: "createdAt",
    headerName: "Erfasst am",
    align: "left",
    width: 100,
    valueFormatter: ({ value }) =>
      value ? format(new Date(value), "dd.MM.yyyy") : "n/A",
  },
  {
    field: "orderedAt",
    headerName: "Bestellt am",
    align: "left",
    width: 100,
    valueFormatter: ({ value }) =>
      value ? format(new Date(value), "dd.MM.yyyy") : "n/A",
  },
  {
    field: "deliveredAt",
    headerName: "Geliefert am",
    align: "left",
    width: 100,
    valueFormatter: ({ value }) =>
      value ? format(new Date(value), "dd.MM.yyyy") : "n/A",
  },
  {
    field: "invoiceNo",
    valueGetter: (params) => {
      return params.row.invoice.invoiceNo &&
        params.row.invoice.invoiceNo !== null
        ? `${params.row.invoice.invoiceNo}`
        : "";
    },
    headerName: "Rechnungs-Nr.",
    align: "left",
    width: 120,
  },
  {
    field: "status",
    headerName: "Status",
    align: "left",
    width: 95,
    renderCell: (params) => {
      return (
        <StatusWrapper status={params.value}>{params.value}</StatusWrapper>
      );
    },
  },
  {
    field: "action",
    headerName: "Aktion",
    align: "center",
    width: 105,
    renderCell: (params) => {
      return (
        <>
          <OrderInvoiceButton invoice={params.row.invoice} />

          <IconButton href={`/shop/orders/${params.row.id}`}>
            <Edit />
          </IconButton>

          <OrderDeleteButton order={params.row} />
        </>
      );
    },
  },
];

export default function OrderList(props) {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [editOrders, setEditOrders] = useState(false);
  const forceReload = useReloadStore((state) => state.forceReload);

  function retrieveOrderDetails() {
    OrderDataService.getAll()
      .then((response) => {
        setOrders(response.data);
        setFilteredOrders(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleSearch(value) {
    setFilteredOrders(
      orders.filter((order) => {
        return (
          order.customer.firstname
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          order.customer.surname.toLowerCase().includes(value.toLowerCase()) ||
          order.article.title.toLowerCase().includes(value.toLowerCase()) ||
          order.article.id
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          order.invoice.invoiceNo
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          order.status.toLowerCase().includes(value.toLowerCase())
        );
      })
    );
  }

  const { paginationModel, filteredList, setPaginationModel, setSelected, handleFormSubmit } = useOrderActions({
    listData: filteredOrders,
    defaultSort: "createdAt",
    defaultOrder: "desc",
  });

  useEffect(() => {
    retrieveOrderDetails();
    useHeaderStore.setState({ title: "Bestellungen" });
  }, []);

  useEffect(() => {
    if (forceReload) {
      retrieveOrderDetails();
      setSelected([]);
      setEditOrders(false);
      useReloadStore.setState({
        forceReload: false,
      });
    }
  }, [forceReload]);

  return (
    <Box py={4} maxWidth={"1400px"}>
      <SearchArea
        handleSearch={handleSearch}
        buttonText="Bestellung erfassen"
        buttonLink="/shop/orders/create"
        searchPlaceholder="Bestellung suchen..."
        secondaryButton={{
          Action: () => setEditOrders(!editOrders),
          Text: "Bestellungen bearbeiten",
        }}
      />
      {editOrders && <EditOrders onSubmit={handleFormSubmit} />}

      <DataGrid
        checkboxSelection={editOrders}
        autoHeight
        width={"1270px"}
        rows={filteredList}
        columns={tableHeading}
        onSelectionModelChange={(newSelection) => {
          setSelected(newSelection);
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </Box>
  );
}
