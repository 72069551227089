import VersionDataService from "../../services/version.service";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Backdrop from "@mui/material/Backdrop";
import Chip from '@mui/material/Chip';
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TelegramIcon from "@mui/icons-material/Telegram";
import EditIcon from '@mui/icons-material/Edit';
import SunEditor from 'suneditor-react';
import '../../css/Editor.css'

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [releases, setReleases] = useState([]);
  const [currentReleases, setCurrentReleases] = useState({ prod: {}, beta: {} });
  const [modifyVersion, setModifyVersion] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [versionsRes, currentVersionsRes] = await Promise.all([
          VersionDataService.getAll(),
          VersionDataService.getCurrent()
        ]);
        setReleases(versionsRes.data);
        setCurrentReleases(currentVersionsRes.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleSendTelegram = async (system, version) => {
    try {
      const data = { system, version };
      const publishstatus = await VersionDataService.publishReleaseNotes(data);
      if (publishstatus.status === 200) {
        // Erfolgreich versendet
      } else {
        // Fehler beim Versenden
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <Backdrop sx={{ zIndex: 1300 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography>
                          <strong>Produktion:</strong> {currentReleases.prod.version}
                        </Typography>
                      }
                    />
                    <IconButton aria-label="telegram" color="primary" onClick={() => handleSendTelegram('prod', currentReleases.prod.version)}>
                      <TelegramIcon />
                    </IconButton>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography>
                          <strong>Beta:</strong> {currentReleases.beta.version}
                        </Typography>
                      }
                    />
                    <IconButton aria-label="telegram" color="primary" onClick={() => handleSendTelegram('beta', currentReleases.beta.version)}>
                      <TelegramIcon />
                    </IconButton>
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            {releases.length > 0 ? (
              releases.map((release, index) => (
                <Grid item xs={12} key={index}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ marginRight: '1em' }}>{release.version}</Typography>
                      {release.beta_active === 1 && <Chip label="Beta" color="secondary" size="small" variant="outlined" />}
                      {release.prod_active === 1 && <Chip label="Produktiv" color="primary" size="small" variant="outlined" />}
                    </AccordionSummary>
                    <AccordionDetails>
                      <IconButton aria-label="edit" color="primary" onClick={() => setModifyVersion(prevVersion => prevVersion !== release.version ? release.version : 0)}>
                        <EditIcon />
                      </IconButton>
                      <SunEditor defaultValue={release.release_notes} height="auto" hideToolbar={release.version !== modifyVersion} disable={release.version !== modifyVersion} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))
            ) : (
              <h2>Keine Versionsinformationen gefunden</h2>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Home;