import http from "../http-common";


class StatisticDataService {
  
  findAll() {
    return http.get(`/statistics`)
  }
}

export default new StatisticDataService();
