import http from "../http-common";


class InvitationDataService {
  
  getAll(){
    return http.get(`/version`)
  }

  getCurrent(){
    return http.get(`/version/current`)
  }

  publishReleaseNotes(data){
    return http.post(`/version/publishreleasenotes`, data)
  }

  create(data) {
    return http.post(`/version`, data)
  }
}

export default new InvitationDataService();
