import { AssignmentTurnedIn } from "@mui/icons-material";
import { Button, Card, TextField, useMediaQuery } from "@mui/material";
import { FlexBox } from "components/flex-box";
import { DatePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";
import { format } from "date-fns";

// ===============================================================

// ===============================================================

const EditOrders = (props) => {
  const {
    searchPlaceholder,
    buttonText,
    buttonLink,
    onSubmit,
    secondaryButton,
  } = props;
  const downSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Card sx={{marginBottom: 2}}>
      <Formik
        onSubmit={(onSubmit)}
        initialValues={{ orderedAt: null, deliveredAt: null }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <FlexBox m={2} gap={2} flexWrap="wrap" sx={{ alignItems: "center"}} >
              <DatePicker
                label="Bestellt am"
                onChange={(newValue) =>
                  setFieldValue("orderedAt", newValue ? format(newValue, 'yyyy-MM-dd') : null)
                }
                slots={{
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    sx: {
                      mb: 1,
                      width: "100%",
                      ...(!downSM && { maxWidth: 350 }),
                      ...(downSM && { maxWidth: "100" }),
                    },
                    size: "medium",
                    error: Boolean(
                      !!touched.createdAt && !!errors.createdAt
                    ),
                    helperText: touched.createdAt && errors.createdAt,
                  },
                }}
                sx={{
                  height: "200px"}}
              />
              <DatePicker
                label="Geliefert am"
                onChange={(newValue) =>
                  setFieldValue("deliveredAt", newValue ? format(newValue, 'yyyy-MM-dd') : null)
                }
                slots={{
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    sx: {
                      mb: 1,
                      width: "100%",
                      ...(!downSM && { maxWidth: 350 }),
                      ...(downSM && { maxWidth: "100" }),
                    },
                    size: "medium",
                    error: Boolean(
                      !!touched.deliveredAt && !!errors.deliveredAt
                    ),
                    helperText: touched.deliveredAt && errors.deliveredAt,
                  },
                }}
              />
              <Button
                color="info"
                fullWidth={downSM}
                size="small"
                variant="contained"
                type="submit"
                startIcon={<AssignmentTurnedIn />}
                sx={{
                  height: 44,
                  marginBottom: "8px"
                }}
              >
                Absenden
              </Button>
            </FlexBox>
          </form>
        )}
      </Formik>
  </Card>
  );
};
EditOrders.defaultProps = {
  buttonText: "Bestellung erfassen",
  searchPlaceholder: "Bestellung suchen...",
};
export default EditOrders;
