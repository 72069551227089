import http from "../http-common";


class SevdeskDataService {
  
  getCustomers(){
    return http.get(`/sevdesk/customer/`)
  }
  
  getInvoices(id){
    return http.get(`/sevdesk/invoice/?customer=${id}`)
  }
  
  getInvoice(id){
    return http.get(`/sevdesk/invoice/${id}`)
  }
  
  getPDF(id){ 
    return http.get(`/sevdesk/invoice/${id}/getPDF`, {responseType: 'blob' })
  }
}

export default new SevdeskDataService();
