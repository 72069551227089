import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import { Formik } from "formik";
import * as yup from "yup"; // GLOBAL CUSTOM COMPONENTS

const VALIDATION_SCHEMA = yup.object().shape({
  customer: yup.string().required("Bitte einen Besteller eingeben"),
  article: yup.object().required("Bitte einen Artikel eingeben"),
  quantity: yup.number().required("Bitte eine Menge eingeben"),
  createdAt: yup.date().required("Bitte ein Erfassungsdatum eingeben"),
  orderedAt: yup.date(),
  deliveredAt: yup.date(),
}); // ================================================================

// ================================================================
const OrderForm = props => {
  const {
    initialValues,
    handleFormSubmit,
    articleValues
  } = props;

  return <Card sx={{
    p: 6
  }}>
      <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={VALIDATION_SCHEMA}>
        {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue
      }) => <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <TextField fullWidth name="customer" label="Besteller" color="info" size="medium" placeholder="Besteller" value={values.name} onBlur={handleBlur} onChange={handleChange} error={!!touched.customer && !!errors.customer} helperText={touched.customer && errors.customer} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <Autocomplete
                  options={articleValues}
                  getOptionLabel={(option) => option.id + ": " + option.title}
                  renderInput={(params) => <TextField
                    {...params}
                    label="Artikel"
                    inputProps={{...params.inputProps, autoComplete: 'off', 'aria-autocomplete': 'none'}}
                    />}
                    onChange={(e, value) => setFieldValue("article", value)}
                  autoComplete="off"
                  fullWidth
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField fullWidth name="quantity" color="info" size="medium" type="number" onBlur={handleBlur} value={values.quantity} label="Menge" onChange={handleChange} placeholder="Menge" error={!!touched.quantity && !!errors.quantity} helperText={touched.quantity && errors.quantity} />
              </Grid>

              <Grid item md={6} xs={12}>
                <DatePicker label="Erfasst am" value={values.createdAt} onChange={newValue => setFieldValue("createdAt", newValue)} slots={{
                  textField: TextField
                }} slotProps={{
                  textField: {
                    sx: {
                      mb: 1
                    },
                    size: "medium",
                    fullWidth: true,
                    error: Boolean(!!touched.createdAt && !!errors.createdAt),
                    helperText: touched.createdAt && errors.createdAt
                  }
                }} />
            </Grid>

            <Grid item md={6} xs={12}>
              <DatePicker label="Bestellt am" value={values.orderedAt} onChange={newValue => setFieldValue("orderedAt", newValue)} slots={{
                textField: TextField
              }} slotProps={{
                textField: {
                  sx: {
                    mb: 1
                  },
                  size: "medium",
                  fullWidth: true,
                  error: Boolean(!!touched.orderedAt && !!errors.orderedAt),
                  helperText: touched.orderedAt && errors.orderedAt
                }
              }} />
            </Grid>

            <Grid item md={6} xs={12}>
              <DatePicker label="Geliefert am" value={values.deliveredAt} onChange={newValue => setFieldValue("deliveredAt", newValue)} slots={{
                textField: TextField
              }} slotProps={{
                textField: {
                  sx: {
                    mb: 1
                  },
                  size: "medium",
                  fullWidth: true,
                  error: Boolean(!!touched.deliveredAt && !!errors.deliveredAt),
                  helperText: touched.deliveredAt && errors.deliveredAt
                }
              }} />
            </Grid>

              <Grid item sm={6} xs={12}>
                <Button variant="contained" color="info" type="submit">
                  Speichern
                </Button>
              </Grid>
            </Grid>
          </form>}
      </Formik>
    </Card>;
};

export default OrderForm;