import React from "react";

function PageNotFound() {
  return (
    <div>
        Page not found.
    </div>
  );
};

export default PageNotFound;