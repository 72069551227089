import { Add } from "@mui/icons-material";
import { Button, useMediaQuery } from "@mui/material";
import { FlexBox } from "components/flex-box";
import SearchInput from "components/SearchInput";
import { Link } from "react-router-dom";

// ===============================================================

// ===============================================================

const SearchArea = (props) => {
  const {
    searchPlaceholder,
    buttonText,
    buttonLink,
    handleSearch,
    secondaryButton,
  } = props;
  const downSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  function onChange(e) {
    handleSearch(e.target.value);
  }
  return (
    <FlexBox mb={2} gap={2} justifyContent="space-between" flexWrap="wrap">
      <SearchInput placeholder={searchPlaceholder} onChange={onChange} />

      {secondaryButton && (
        <Button
          color="info"
          fullWidth={downSM}
          variant="outlined"
          startIcon={<Add />}
          component={secondaryButton.Link && Link}
          to={secondaryButton.Link && secondaryButton.Link}
          onClick={secondaryButton.Action && secondaryButton.Action}
          sx={{
            minHeight: 44,
          }}
        >
          {secondaryButton.Text}
        </Button>
      )
      }
      <Button
        color="info"
        fullWidth={downSM}
        variant="contained"
        startIcon={<Add />}
        component={Link}
        to={buttonLink}
        sx={{
          minHeight: 44,
        }}
      >
        {buttonText}
      </Button>
    </FlexBox>
  );
};
SearchArea.defaultProps = {
  buttonText: "Bestellung erfassen",
  searchPlaceholder: "Bestellung suchen...",
};
export default SearchArea;
