import './css/App.css';
import React, { Fragment } from 'react';
import { CssBaseline } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { withRouter, Switch, Route } from 'react-router-dom';
import { authenticationService } from './auth/authentication.service';
import { PrivateRoute } from './components/PrivateRoute';
import { useAlertMessageStore } from './store/store';
import AlertMessage from './components/AlertMessage';
import Container from '@mui/material/Container';
import AppHeader from './components/AppHeader';

import Home from './pages/Home';
import LegalNotice from './pages/LegalNotice';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';
import Privacy from './pages/Privacy';
import Version from './pages/Version/Version';
import ShopOrders from './pages/Shop/orders';
import ShopOrdersPending from './pages/Shop/ordersPending';
import ShopOrdersOrdered from './pages/Shop/ordersOrdered'
import CreateOrder from './pages/Shop/createOrder';
import OrderDetails from './pages/Shop/orders/orderDetails';


const useStyles = (theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      marginLeft: '240px',
      maxWidth: 'calc(99.9% - 240px)',
    },
    [theme.breakpoints.up("xxxl")]: {
      marginLeft: 'auto',
      maxWidth: '1920px',
    },
  },
  main: {
    marginTop: "64px",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    /*[theme.breakpoints.between('md','xxl')]: {
        paddingLeft: '240px'
      },*/
  },
});
class MainWrapper extends React.Component {

    constructor() {
        super();
         this.state = {
          searchString: "",

          alertMessageStore: useAlertMessageStore.getState(),

          currentUser: null,
          forceCollectionUpdate: false,
        };
        this.setStateValue = this.setStateValue.bind(this);
        this.resetAllStates = this.resetAllStates.bind(this);
        this.resetSearchString = this.resetSearchString.bind(this);
        this.handleSearchStringChange = this.handleSearchStringChange.bind(this);
    }


  componentDidMount() {
    authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    this.alertMessage = useAlertMessageStore.subscribe((alertMessageStore) => {
      this.setState({alertMessageStore})
   }, (state) => state);
    this.props.history.listen(() => {
      if (window.swUpdateReady) {
        window.swUpdateReady = false;
        window.stop();
        window.location.reload();
      }
    });
  }

handleSearchStringChange(e) {
  this.setState({
    searchString: e.target.value,
  })
}
resetSearchString() {
  this.setState({
    searchString: "",
  })
}

setStateValue(key, value){
  var state = {}
  state[key] = value
  this.setState(state)
}

resetAllStates() {
  this.setState({
    alertMessage: false,
  })
}
 
    render() {
        const { currentUser, alertMessageStore } = this.state

        const { classes } = this.props;
        return (
            <Fragment>
            
            <CssBaseline />
            { alertMessageStore.alertMessage &&
                <AlertMessage message={alertMessageStore.alertMessage.message} severity={alertMessageStore.alertMessage.severity} resetAllStates={this.resetAllStates} />
              }
          { currentUser && 
            <>
            <PrivateRoute component={AppHeader}
               handleSearchStringChange={this.handleSearchStringChange}
               searchString={this.state.searchString}
               resetSearchString={this.resetSearchString}
               setStateValue={this.setStateValue}
               forceCollectionUpdate={this.state.forceCollectionUpdate}
            />  
            <Container className={classes.container}>
              <main className={classes.main} >
                <Switch>
                  <PrivateRoute exact path='/' component={Home} setStateValue={this.setStateValue} />
                  <PrivateRoute exact path='/privacy' component={Privacy} setStateValue={this.setStateValue} />
                  <PrivateRoute exact path='/legalNotice' component={LegalNotice} setStateValue={this.setStateValue} />
                  <PrivateRoute exact path='/version' component={Version} setStateValue={this.setStateValue} />
                  <PrivateRoute exact path='/shop/orders' component={ShopOrders} setStateValue={this.setStateValue} />
                  <PrivateRoute exact path='/shop/orders_pending' component={ShopOrdersPending} setStateValue={this.setStateValue} />
                  <PrivateRoute exact path='/shop/orders_ordered' component={ShopOrdersOrdered} setStateValue={this.setStateValue} />
                  <PrivateRoute exact path='/shop/orders/create' component={CreateOrder} setStateValue={this.setStateValue} />
                  <PrivateRoute exact path='/shop/orders/:id' component={OrderDetails} setStateValue={this.setStateValue} />
                  <Route path="*" exact component={PageNotFound} />
                </Switch>     
              </main>
            </Container> 
            </> 
          } 
          { !currentUser && 
            <main>
            <Switch>
               <Route path='*' exact render={(props) => 
                <Login {...props}
                setStateValue={this.setStateValue}
                />} 
              />      
            </Switch>   
            </main>
          }
           
            </Fragment>
        );
    }
}

export default withRouter(withStyles(useStyles)(MainWrapper));