import http from "../http-common";


class OrderDataService {
  
  getSingle(id){
    return http.get(`/order/${id}`)
  }
  
  getAll(){
    return http.get(`/order`)
  }

  getPending(){
    return http.get(`/order?status=pending`)
  }

  getOrdered(){
    return http.get(`/order?status=ordered`)
  }

  create(data) {
    return http.post(`/order`, data)
  }

  update(id, data) {
    return http.put(`/order/${id}`, data)
  }

  updateMultiple(data) {
    return http.patch(`/order/`, data)
  }

  getAllArticles(){
    return http.get(`/article`)
  }

  deleteSingle(id) {
    return http.delete(`/order/${id}`)
  }
}

export default new OrderDataService();
