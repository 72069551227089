import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material";
import { deDE } from '@mui/material/locale';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import de from 'date-fns/locale/de';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      xxxl: 2560,
    }
  },
  palette: {
    primary: {
      main: '#547890',
    },
    error: {
      100: "#FFEAEA",
      200: "#FFCBCB",
      300: "#FFA9A9",
      400: "#FF6D6D",
      500: "#FF5353",
      600: "#FF4C4C",
      700: "#FF4242",
      800: "#FF3939",
      900: "#FF2929",
      main: "#E94560"
    },
    success: {
      100: "#E7F9ED",
      200: "#C2F1D1",
      300: "#99E8B3",
      400: "#52D77E",
      500: "#33D067",
      600: "#2ECB5F",
      700: "#27C454",
      800: "#20BE4A",
      900: "#0b7724",
      main: "rgb(51, 208, 103)"
    },
    warning: {
      100: "#FFF8E5",
      main: "#FFCD4E",
      contrastText: "#FFFFFF"
    },
    info: {
      50: "#f3f5f9",
      100: "#DBF0FE",
      200: "#B8DEFE",
      300: "#94C9FE",
      400: "#7AB6FD",
      500: "#4E97FD",
      600: "#3975D9",
      700: "#2756B6",
      800: "#183C92",
      900: "#0E2979",
      main: "#4E97FD",
      contrastText: "#FFFFFF"
    },
  }
}, deDE);


ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
    <BrowserRouter>
    <App />
    </BrowserRouter>
    </ThemeProvider>
    </StyledEngineProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorkerRegistration.register();