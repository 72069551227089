import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

import OrderDeleteDialog from "./order-delete-dialog";

import useOrderActions from "hooks/useOrderActions";

// ========================================================================

// ========================================================================

const OrderDeleteButton = ({ order }) => {
  const { openModal, toggleDeleteDialog, handleDeleteClick } = useOrderActions({});

  return (
    <>
      <OrderDeleteDialog
        openDialog={openModal}
        handleCloseDialog={toggleDeleteDialog}
        handleDeleteClick={handleDeleteClick}
        order={order}
      />
      <IconButton onClick={toggleDeleteDialog}>
        <Delete />
      </IconButton>
    </>
  );
};
export default OrderDeleteButton;
