import React, { Component } from 'react'
import { authenticationService } from '../auth/authentication.service';
import { Formik } from 'formik'
import { object, string } from 'yup'
import { CssBaseline } from "@mui/material";
import { withStyles } from "@mui/styles";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function Copyright() {
  return (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://spieleordner.de/">
      Spieleordner
    </Link>{' '}
    {new Date().getFullYear()}
  </Typography>
  );
  }

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })  


class Login extends Component {
  constructor(props) {
    super(props);
    // redirect to home if already logged in
      if (authenticationService.currentUserValue) { 
        const from = this.props.location.pathname ||  "/" ; 
        this.props.history.push({pathname: from});
      }
    this.state = {
    }
  }

  _handleSubmit = ({
    username,
    password,
    token,
    setSubmitting,
    setError,
    resetForm,
  }) => {

    authenticationService.login(username, password, token)
    .then(
        user => {
            const { from } = this.props.location.pathname || { from: { pathname: "/" } };
            this.props.history.push(from); 
        },
        error => {
          setSubmitting(false)
          this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler bei der Anmeldung', key: Math.random() })
        }
    ); 
  }

  render() {
    const { classes } = this.props; 

    return (
      <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Formik
        initialValues={{
          username: '',
          password: '',
          token: '',
        }}
        validationSchema={object().shape({
          username: string().required('Bitte einen Benutzernamen eingeben'),
          password: string().required('Bitte ein Passwort eingeben'),
          token: string().required('Bitte einen Token eingeben').length(6,'Bitte einen gültigen Token eingeben')
        })}
        onSubmit={(
          { username, password, token },
          { setSubmitting, setStatus, setError, resetForm }
        ) =>
          this._handleSubmit({
            username,
            password,
            token,
            setSubmitting,
            setStatus,
            resetForm,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props
          return ( <>        
            { isSubmitting && 
              <Backdrop className={classes.backdrop} open>
                <CircularProgress color="inherit" />
               </Backdrop>
            }
            <div className={classes.paper}>
              <img alt="logo" src="/logo192.png" style={{display: 'block', margin: 'auto' }} /> 
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="username"
                    label="Benutzername"
                    name="username"
                    variant="outlined"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.username && errors.username)}
                  />
                  <FormHelperText
                    error={Boolean(touched.username && errors.username)}
                  >
                    {touched.username && errors.username
                      ? errors.username
                      : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(touched.password && errors.password)}>
                  <TextField
                    name="password"
                    label="Passwort"
                    type="password"
                    id="password"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.password && errors.password)}
                  />
                  <FormHelperText
                    error={Boolean(touched.password && errors.password)}
                  >
                    {touched.password && errors.password ? errors.password : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(touched.token && errors.token)}>
                  <TextField
                    name="token"
                    label="Token"
                    type="token"
                    id="token"
                    variant="outlined"
                    value={values.token}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.token && errors.token)}
                  />
                  <FormHelperText
                    error={Boolean(touched.token && errors.token)}
                  >
                    {touched.token && errors.token ? errors.token : ''}
                  </FormHelperText>
                </FormControl>
                <Button
                  type="submit"
                  variant="contained" 
                  color="primary"
                  fullWidth
                  disabled={Boolean(!isValid || isSubmitting)}
                  className={classes.submit}
                >
                  {'Anmelden'}
                </Button>
              </form>
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
            
          </>)
          
        }}
      />
      </Container>
    )
  }
}

export default withStyles(useStyles)(Login)