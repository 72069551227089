import React from 'react';
import { useState } from 'react';
import { authenticationService } from '../auth/authentication.service';
import { history } from '../helpers/history';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import { Link, matchPath } from 'react-router-dom';
import { withRouter } from "react-router";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Countdown, { zeroPad } from "react-countdown";
import { ListItemButton } from '@mui/material';
import { useHeaderStore } from '../store/store';
//const jwt = require('jsonwebtoken');


const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    maxWidth: "1432px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "240px",
      maxWidth: "calc(99.9% - 240px)",
    },
    [theme.breakpoints.up("xxl")]: {
      paddingLeft: "24px",
    },
    [theme.breakpoints.up("xxxl")]: {
      marginLeft: "auto",
      maxWidth: "1872px",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
    collectionSelection: {
      fontSize: '14px',
    },
  }  
}));


function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [init, setInit] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { location, pageTitle, } = props;
  //const token = jwt.decode(JSON.parse(localStorage.getItem('currentUser')).token)
  //const session_exp = token.exp * 1000
  const headerStore = useHeaderStore(state => state);
  

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    authenticationService.logout();
    history.push('/');
  }


  const drawer = (
    <div>
      <div className={classes.toolbar}>
      <img src="/logo192.png" style={{display: 'block', margin: 'auto' }} /> 
      </div>
      <Divider />
      <List>
    {[
      { 
        titel: 'Übersicht',
        secondary: '',
        route: '/',
      },
     {
        titel: 'Versionen',
        secondary: '',
        route: '/version'
     }].map((menuObject, index) => (
        <ListItemButton onClick={ mobileOpen ? handleDrawerToggle : undefined } component={Link} to={menuObject.route} key={menuObject.titel} on>
          <ListItemText primary={menuObject.titel} secondary={menuObject.secondary}/>
        </ListItemButton>
    ))}
      <Divider />
      <ListItem>
        <ListItemText secondary="Shop" />
      </ListItem>
        <ListItemButton onClick={ mobileOpen ? handleDrawerToggle : undefined } component={Link} to='/shop/orders_pending' key='shop/orders_pending' on>
          <ListItemText primary='Offene Bestellungen' />
        </ListItemButton>
        <ListItemButton onClick={ mobileOpen ? handleDrawerToggle : undefined } component={Link} to='/shop/orders_ordered' key='shop/orders_ordered' on>
          <ListItemText primary='Platzierte Bestellungen' />
        </ListItemButton>
        <ListItemButton onClick={ mobileOpen ? handleDrawerToggle : undefined } component={Link} to='/shop/orders' key='shop/orders' on>
          <ListItemText primary='Alle Bestellungen' />
        </ListItemButton>
      <Divider />
      <ListItem button onClick={logout}>
          <ListItemText primary="Abmelden" />
      </ListItem>
    </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Container className={classes.container}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {(location.pathname !== "/") &&
            <IconButton aria-label="back" color="inherit" onClick={props.history.goBack} ><ChevronLeftIcon /></IconButton>
          }
          <Typography variant="h6" > {headerStore.title} </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      
      <nav className={classes.drawer} >
        <Hidden lgUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >      
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      </div>

      );}

      
export default withRouter(ResponsiveDrawer);