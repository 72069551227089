import { IconButton } from "@mui/material";
import { Description } from "@mui/icons-material";

import useOrderActions from "hooks/useOrderActions";

// ========================================================================

// ========================================================================

const OrderInvoiceButton = ({ invoice }) => {
  const { handleInvoiceClick } = useOrderActions({});

  return (
    <>
      <IconButton onClick={() => handleInvoiceClick(invoice)}>
        <Description />
      </IconButton>
    </>
  );
};
export default OrderInvoiceButton;
